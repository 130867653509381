<template>
    <div class="login_container fade">
        <div class="left">
            <v-img contain class="logo" src="@/assets/logo.png"></v-img>
        </div>

        <div class="right">

            <v-card class="login_card" tile>
                <v-card-subtitle><b>Entre com suas informações de login</b></v-card-subtitle>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="email" dense label="Email" outlined hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="password" dense type="password" label="Senha" outlined hide-details="auto"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-row>
                        <v-col cols="12">
                            <v-btn class="primary mb-2" block large @click.native="login(email, password)">
                                Entrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>

            <div class="new-account white--text">
                Ainda não tem uma conta?
                <v-btn small class="white--text ml-4 mb-2" outlined @click.native="dialogCreateUser = true">Cadastre-se
                    aqui</v-btn>
            </div>


            <div class="new-account white--text">
                <v-btn small class="white--text" outlined @click.native="dialogRecoveryPassword = true">Esqueci minha
                    senha</v-btn>
            </div>

        </div>


        <v-dialog v-model="dialogRecoveryPassword" max-width="500">
            <v-card>
                <v-card-title class="text-h5" style="word-break: break-word;">
                    Envio de senha
                </v-card-title>
                <v-card-text>Digite seu e-mail abaixo para receber uma senha temporária.</v-card-text>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="email2" label="Email" outlined hide-details="auto"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="pb-4">
                    <v-btn color="error lighten-1" outlined @click="dialogRecoveryPassword = false">
                        Cancelar
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="dialogRecoveryPassword = false; sendNewPassword()">
                        Enviar senha
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="dialogCreateUser" max-width="760" class="mx-0">
            <v-card>
                <v-card-title class="text-h5" style="word-break: break-word;">
                    Nova conta
                </v-card-title>

                <v-card-subtitle>
                    Escolha o seu tipo de conta: <b>"quero trabalhar"</b> (contratado) ou <b>"quero contratar"</b> (contratante).
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" lg="6" md="6" sm="12">
                            <v-select chips small-chips outlined v-model="newUser.role" :items="roles" item-text="name" item-value="id" label="Tipo de conta" hide-details="auto">
                                <template #selection="{ item }">
                                    <v-chip small color="primary">{{ item.name }}</v-chip>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" sm="12">
                            <v-text-field v-model="newUser.name" label="Seu nome" outlined hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="newUser.email" label="Email" outlined hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" sm="12">
                            <v-text-field type="password" v-model="newUser.password" label="Senha" outlined hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" sm="12">
                            <v-text-field type="password" v-model="newUser.password2" label="Confirmação de senha" outlined hide-details="auto"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-text v-if="newUser.role === 'contractor'">
                    <v-divider class="mb-4"></v-divider>
                    <div class="text-h6 mb-2">Dados do contratante</div>
                    <v-row>
                        <v-col cols="12" lg="6" md="6" sm="12">
                            <v-text-field v-model="newUser.contractor_name" label="Nome do contratante ou empresa" outlined hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" sm="12">
                            <v-select chips small-chips outlined v-model="newUser.contractor_segment" :items="$segments" item-text="name" item-value="name" label="Segmento de atuação" hide-details="auto">
                                <template #selection="{ item }">
                                    <v-chip small color="primary"><v-icon small class="mr-2">{{ item.icon }}</v-icon>{{
                                        item.name }}</v-chip>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" sm="12">
                            <v-select chips small-chips outlined v-model="newUser.contractor_document_type" :items="$document_types" label="Tipo de documento" hide-details="auto">
                                <template #selection="{ item }">
                                    <v-chip small color="primary">{{ item }}</v-chip>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" sm="12">
                            <v-text-field v-model="newUser.contractor_document" v-mask="newUser.contractor_document_type == 'CPF' ? '###.###.###-##' : '##.###.###/####-##'" label="Documento" outlined hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="newUser.contractor_description" rows="4" label="Descrição do contratante ou empresa" placeholder="Escreva aqui uma descrição de até 5 linhas sobre essa empresa." outlined hide-details="auto" />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="pb-4">
                    <v-btn color="error lighten-1" outlined @click="dialogCreateUser = false">
                        Cancelar
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :loading="loading" @click="validateUser()">
                        Criar conta
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
const { cnpj, cpf } = require('cpf-cnpj-validator');

export default {
    name: "LoginView",

    data() {
        return {
            email: "",
            password: "",
            email2: "",
            remember: true,
            dialogRecoveryPassword: false,
            dialogCreateUser: false,
            loading: false,
            newUser: {
                role: 'user'
            },
            roles: [
                { id: 'user', name: 'Quero trabalhar' },
                { id: 'contractor', name: 'Quero contratar' }
            ],

        };
    },

    components: {},

    async mounted() {
        const resp = await this.$api.getUser();
        if (resp && resp._id) {
            if (resp.role == 'user') {
                this.$router.push("/meu-perfil");
            }
            else {
                this.$router.push("/conta");
            }
        }
    },

    methods: {
        async login(email, password) {
            email = email.split(' ').join('');
            const resp = await this.$api.login(email, password, true);
            if (resp && resp.login_ok) {
                if (resp.user?.role == 'user') {
                    this.$router.push("/meu-perfil");
                }
                else {
                    this.$router.push("/conta");
                }

                return;
            }

            if (resp && resp.error && resp.message) {
                this.$emitToastr('error', resp.message)
                return;
            }
        },

        async sendNewPassword() {
            const resp = await this.$api.recoveryPassword(this.email2);

            if (resp && !resp.error) this.$emitToastr("success", "Nova senha gerada com sucesso. Por favor verifique sua caixa de entrada ou pasta de spam para ver a nova senha.")
        },

        validateUser() {
            //VALIDAÇÃO DE USER
            if (!this.newUser.role || !this.newUser.name || !this.newUser.email || !this.newUser.password || !this.newUser.password2) return this.$emitToastr("error", "Por favor preencha todos os campos");
            if (this.newUser.password.length < 6) return this.$emitToastr("error", "A senha deve ter pelo menos 6 dígitos");
            if (this.newUser.password != this.newUser.password2) return this.$emitToastr("error", "As senhas não conferem");

            if (this.newUser.role != 'user' && this.newUser.role != 'contractor') return this.$emitToastr("error", "Tipo de conta inválido");

            this.newUser.email = this.newUser.email.split(' ').join('');
            if (!this.$validateEmail(this.newUser.email)) return this.$emitToastr("error", "E-mail inválido");

            //VALIDAÇÃO DE contractor
            if (this.newUser.role === 'contractor') {
                if (!this.newUser.contractor_name || !this.newUser.contractor_segment || !this.newUser.contractor_document_type || !this.newUser.contractor_document || !this.newUser.contractor_description) return this.$emitToastr("error", "Por favor preencha todos os campos");

                if (this.newUser.contractor_document_type === 'CNPJ') {
                    if (!cnpj.isValid(this.newUser.contractor_document)) return this.$emitToastr("error", "CNPJ inválido");
                }
                else {
                    if (!cpf.isValid(this.newUser.contractor_document)) return this.$emitToastr("error", "CPF inválido");
                }
            }

            this.register()
        },

        async register() {
            this.loading = true;
            const resp = await this.$api.createUser(this.newUser);
            this.loading = false;

            if (!resp.error && resp.message == 'ok') {
                this.dialogCreateUser = false;
                this.$emitToastr("success", "Conta criada com sucesso!");
                this.login(this.newUser.email, this.newUser.password);
            }
        }
    },
};
</script>

<style scoped>
.login_container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;
    align-content: center;
    align-items: center;
}

.left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: rgb(0, 0, 0);
    background: radial-gradient(var(--laranja_2), var(--laranja_1));
}

.right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    padding: 5%;
    background: linear-gradient(to right, #5586c7, #5586c7 25%, #dd4449 25%, #dd4449 50%, #56b47f 50%, #56b47f 75%, #eba83f 75%, #eba83f 100%);
    background-size: 400% 100%;
    animation: slideBackground 30s ease-in-out infinite;
}

.login_card {
    max-width: 400px;
    padding: 10px;
}

.logo {
    max-width: 50vw;
    max-height: 600px;
}

.new-account {
    margin-top: 15px;
    font-size: 16px;
}

.new-account-button {
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
}

/* Animação */
@keyframes slideBackground {
    0% {
        background-position: 0% 0%;
    }

    23.5% {
        background-position: 0% 0%;
    }


    25% {
        background-position: -33.333%, 0%;
    }

    48.5% {
        background-position: -33.333%, 0%;
    }


    50% {
        background-position: -66.666%, 0%;
    }

    73.5% {
        background-position: -66.666%, 0%;
    }


    75% {
        background-position: -100%, 0%;
    }

    98.5% {
        background-position: -100%, 0%;
    }


    100% {
        background-position: -133.333%, 0%;
    }
}

@media only screen and (max-width: 1000px) {
    .login_container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
        align-content: center;
        align-items: center;
    }

    .left {
        height: 25vh;
    }

    .right {
        text-align: center;
        justify-content: flex-start;
    }

    .logo {
        width: 90vw;
        max-width: 600px;
        max-height: 500px;
    }


    .login_card {
        margin-top: 20px;
        max-width: 90vw;
    }
}
</style>