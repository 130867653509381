<template>
    <div class="menu-page">
        <v-card class="">
            <v-card-title>
                Administração de Cursos
            </v-card-title>



            <v-card-text>
                <v-row class="mb-4">
                    <v-col cols="12" lg="6" md="6" sm="12">
                        <v-text-field dense v-model="search" append-icon="mdi-magnify" label="Busque por título do curso" clearable outlined single-line hide-details style="max-width: 500px;"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" sm="12" class="d-flex">
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="newObject">Criar novo curso</v-btn>
                    </v-col>
                </v-row>

                <v-data-table :headers="headers" :items="objects" :search="search" :loading="loading">

                    <template v-slot:[`item.created`]="{ item }">
                        {{ new Date(item.created).toLocaleString('pt-BR').replace(',', ' -') }}
                    </template>


                    <template v-slot:[`item.active`]="{ item }">
                        {{ item.active ? 'Sim' : 'Não' }}
                    </template>


                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon @click="object = item; deleteDialog = true">
                                    <v-icon medium class="mr-2" color="error lighten-1" v-bind="attrs" v-on="on">mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Apagar</span>
                        </v-tooltip>

                        <v-tooltip top v-if="!item.ignore">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon medium color="primary" @click="object = item; dialog = true" v-bind="attrs" v-on="on">mdi-file-edit-outline</v-icon>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialog" max-width="1200" class="mx-0">
            <v-card>
                <v-card-title class="text-h5" style="word-break: break-word;">
                    Editar curso
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-text-field v-model="object.title" label="Título" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12">
                            <v-textarea v-model="object.description" rows="3" label="Descrição" outlined hide-details="auto"></v-textarea>
                        </v-col>


                        <v-col cols="12" lg="6" md="6" sm="12">
                            <v-switch dense class="pl-2" v-model="object.active" inset label="Curso ativo? (visível)"></v-switch>
                        </v-col>
                    </v-row>

                    <v-row v-if="object.cids && object.cids.length > 0">
                        <v-col cols="12" v-for="(item, index) in object.cids" :key="'cids_' + index">
                            <v-card dense outlined>
                                <v-card-title>
                                    <v-icon color="success" class="mr-2">mdi-file-check-outline</v-icon> {{ item.name }}
                                    <v-spacer></v-spacer>
                                    <v-btn class="primary mx-2" @click="$openLink(item.url)">Abrir</v-btn>
                                </v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-title class="text-h5" style="word-break: break-word;">
                    Conteúdo do curso
                </v-card-title>

                <v-card-text>
                    <v-row v-for="(content, index) in object.content" :key="`content_${index}`">
                        <v-col cols="12" class="d-flex flex-row justify-end">
                            <v-btn color="error" outlined @click="object.content.splice(index, 1);">
                                <v-icon class="mr-2">mdi-trash-can-outline</v-icon>
                                Apagar {{ content.title }}
                            </v-btn>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field v-model="content.title" label="Título" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea v-model="content.description" rows="3" label="Descrição" outlined hide-details="auto"></v-textarea>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea v-model="content.content" rows="5" label="Conteúdo" outlined hide-details="auto"></v-textarea>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field v-model="content.video" label="Link do vídeo embed" placeholder="exemplo: https://www.youtube.com/embed/WGdLLZL5Xm8" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-divider class="my-2"></v-divider>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-text>
                    <v-btn color="primary" @click="addModule">Adicionar módulo</v-btn>
                </v-card-text>

                <v-card-actions class="pb-4">
                    <v-btn color="error lighten-1" outlined @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :loading="loading" @click="save">
                        Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog overlay-opacity="0.5" overlay-color="black" v-model="deleteDialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar curso
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar o curso <b>{{ object.title }}</b>? Essa operação é irreversível.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="deleteDialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="deleteCourse()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "AdminCoursesView",
    data() {
        return {
            objects: [],
            object: {},
            search: "",
            loading: true,
            dialog: false,
            deleteDialog: false,
            headers: [
                { text: 'Título', value: 'title', sortable: false },
                { text: 'Ativo', value: 'active', sortable: false },
                { text: 'Criação', value: 'created', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ]
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        }
    },

    async mounted() {
        this.getCourses();
    },

    methods: {
        async getCourses() {
            this.loading = true;
            const resp = await this.$api.getAllCourses();
            this.loading = false;

            if (!resp.error && resp.message) this.objects = resp.message;
        },

        addModule() {
            if (!this.object.content) this.object.content = [];
            this.object.content.push({})
        },

        async save() {
            if (!this.object.title) return this.$emitToastr("error", "Título do curso obrigatório");
            if (!this.object.description) return this.$emitToastr("error", "Descrição do curso obrigatória");
            if (!this.object.content || this.object.content.length === 0) return this.$emitToastr("error", "Conteúdo obrigatório");

            this.loading = true;
            const resp = await this.$api.createOrUpdateCourse(this.object);
            this.loading = false;

            if (!resp.error && resp.message) {
                this.$emitToastr("success", "Curso atualizado com sucesso!");
                this.dialog = false;
                this.object = {};
                this.getCourses()
            }
        },

        newObject() {
            this.object = { title: '', description: '', active: true, content: [] };
            this.dialog = true;
        },

        async deleteCourse() {
            this.loading = true;
            this.object.filed = true;
            const resp = await this.$api.createOrUpdateCourse(this.object);
            this.loading = false;

            if (!resp.error && resp.message) {
                this.$emitToastr("success", "Curso apagado com sucesso!");
                this.deleteDialog = false;
                this.getCourses()
            }
        }
    },
};
</script>

<style scoped></style>